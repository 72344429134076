import { ReactTyped } from "react-typed";
import bg from "../../assets/bg.png";
import { motion, useInView, useAnimation } from "framer-motion";
import React, { useRef, useEffect, useState } from "react";
import { Reveal } from "../Animation/Reveal";

const Mainimg = () => {
  return (
    <div className="relative inset-0 flex items-center justify-center w-full h-full min-h-screen">
      <img
        src={bg}
        alt=""
        className="fixed w-full h-screen z-[-1] object-cover"
      />
      <div className="fixed inset-0 w-full h-full bg-black opacity-40"></div>

      <Reveal>
        <div className="relative flex flex-col items-center gap-5 text-center z-2">
          <h1 className="px-3 pb-4 text-2xl font-bold leading-9 text-gray-900 sm:text-6xl">
            Wir machen speziell für
            <br></br>Ihr Projekt{" "}
            <ReactTyped
              className="font-normal text-[#FFA500]"
              strings={[" Fassaden ", " Fertigteilen ", " Schalung "]}
              typeSpeed={40}
              backSpeed={50}
              loop
            />
          </h1>
        </div>
      </Reveal>
    </div>
  );
};
export default Mainimg;
