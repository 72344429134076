const images = [];

// Importing images from "img9.jpg" to "img36.jpg"
for (let i = 9; i <= 36; i++) {
  images.push({
    url: require(`../../assets/oferta/img${i}.jpg`),
    alt: `Image ${i}`, // Example alt text; you can adjust as needed
    description: `Description for img${i}`, // Optional: Add any descriptions you need
  });
}

// Importing images from "IMG-20241014-WA0001.jpg" to "IMG-20241014-WA0116.jpg"
for (let i = 1; i <= 116; i++) {
  const paddedIndex = i.toString().padStart(4, "0"); // Pads the index with leading zeros, e.g., 0001, 0016, etc.
  images.push({
    url: require(`../../assets/oferta/IMG-20241014-WA${paddedIndex}.jpg`),
    alt: `Image WA${paddedIndex}`, // Example alt text; you can adjust as needed
    description: `Description for IMG-20241014-WA${paddedIndex}`, // Optional description
  });
}

export default images;
