import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Realizacja from "./pages/Realizacja";
import Offerta from "./pages/Offerta";
import NotFoundPage from "./pages/NotFoundPage";
import AllBlogs from "./components/Blogs/AllBlogs";
import BlogPage from "./components/Blogs/BlogPage";
import Blog1 from "./components/Blogs/Blog1";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/angebot" element={<Offerta />} />
        <Route path="/realisierung" element={<Realizacja />} />
        <Route path="/uberuns" element={<About />} />
        <Route path="/kontakt" element={<Contact />} />
        <Route path="/blogs" element={<AllBlogs />} />
        <Route path="/blog/:id" element={<BlogPage />} />
        <Route path="/blog/4" element={<Blog1 />} />
        <Route component={NotFoundPage} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
