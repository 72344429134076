import Main from "../components/Oferta/Main";
import { Helmet } from "react-helmet-async";
const Offerta = () => {
  return (
    <div>
      <Helmet>
        <title>Angebote - Lechbud</title>
        <meta
          name="description"
          content="Entdecken Sie unsere exklusiven Angebote bei Lechbud. Hochwertige Produkte zu unschlagbaren Preisen."
        />
        <meta
          name="keywords"
          content="Angebote, Sonderangebote, Rabatte, Produkte"
        />
        <meta property="og:title" content="Angebote - Lechbud" />
        <meta
          property="og:description"
          content="Entdecken Sie unsere besten Angebote und Rabatte auf hochwertige Produkte."
        />
        <meta property="og:url" content="https://lechbud.de/angebot" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Main />
    </div>
  );
};
export default Offerta;
