import React from "react";
import Hero from "../components/Realizacja/Hero";
import Experience from "../components/Main/Experience";
import Galeri from "../components/Realizacja/Galeri";
import { Helmet } from "react-helmet-async";

import images from "../components/Realizacja/imges";

const Realizacja = () => {
  return (
    <div>
      <Helmet>
        <title>Projekte und Realisierungen - Lechbud</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über unsere erfolgreichen Projekte und Realisierungen bei Lechbud."
        />
        <meta
          name="keywords"
          content="Projekte, Realisierungen, Erfolgreiche Projekte, Arbeiten"
        />
        <meta
          property="og:title"
          content="Projekte und Realisierungen - Lechbud"
        />
        <meta
          property="og:description"
          content="Sehen Sie unsere erfolgreich abgeschlossenen Projekte und Arbeiten."
        />
        <meta property="og:url" content="https://lechbud.de/realisierung" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Hero />
      <div className="relative z-3">
        <Experience />
        {/* Passing the imported images as props to the Galeri component */}
        <Galeri photos={images} />
      </div>
    </div>
  );
};

export default Realizacja;
