import React from "react";
import bg from "../../assets/bg2.png";
import { Link } from "react-router-dom";
import { Reveal } from "../Animation/Reveal";

const AboutUsSmall = () => {
  return (
    //mt-[-70px] md:mt-[-40px]
    <div className="relative flex flex-col items-center justify-around w-full h-full md:h-[80vh]  px-4 mx-auto text-center  py-[100px] gap-5">
      <img src={bg} alt="" className="absolute object-cover w-full h-full" />
      <Reveal>
        <div className="flex flex-col gap-5 z-[1] ">
          <h1 className="pb-4 text-3xl font-bold leading-9 text-black lg:text-4xl">
            Wer wir sind
          </h1>

          <Link
            to="/uberuns"
            className="bg-transparent text-[#FFA500] bg-white font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent"
          >
            Weitere Informationen
          </Link>
        </div>
      </Reveal>
      <Reveal>
        <h2 className="max-w-[600px] text-sm font-normal leading-6 text-black z-[1] bg-white/70 p-4 rounded-xl">
          Unsere Firma spezialisiert sich auf die Planung und Montage von
          Fassaden sowie die Produktion von hochwertigen Fertigteilen. Wir
          bieten innovative Lösungen für moderne und energieeffiziente
          Gebäudehüllen. Unsere Produkte zeichnen sich durch Langlebigkeit und
          ästhetisches Design aus. Wir garantieren schnelle Lieferung und
          professionelle Installation.
        </h2>
      </Reveal>
    </div>
  );
};

export default AboutUsSmall;
