import React from "react";
import RecommendedBlogs from "./RecommendedBlogs";
import bg from "../../assets/blogs/imgblog1.png";
import bg2 from "../../assets/blogs/imgblog2.png";
import bg3 from "../../assets/blogs/imgblog3.jpg";
const BlogComponent = () => {
  return (
    <div>
      <article className="bg-white border border-gray-200 p-6 rounded-md max-w-6xl mx-auto mt-[150px] shadow-lg flex flex-col gap-4 font-sans">
        <h1 className="text-2xl font-bold text-gray-800 mb-4">
          Effizientes Lüftungssystem für Ihr Zuhause
        </h1>
        <p className="text-gray-700 text-sm mb-6">
          Wussten Sie, dass eine durchschnittliche Familie pro Jahr bis zu
          40.000 Liter Feuchtigkeit in ihrer Wohnung produziert? Ein effektives{" "}
          <b>Lüftungssystem</b> ist wichtig, um Schimmel zu vermeiden und ein
          gesundes Klima zu schaffen. Wir schauen uns an, wie moderne
          Lüftungssysteme funktionieren und was sie für Ihr Zuhause tun können.
        </p>

        <div className="wp-img mb-6">
          <img
            src={bg}
            alt="Lüftungssystem Beispiel"
            className="mx-auto max-w-full h-auto"
          />
        </div>

        <div className="wp-box bg-white border border-gray-300 p-5 rounded-md mb-6">
          <div className="wed-field mb-4">
            <label className="block text-sm text-gray-600 font-semibold mb-1">
              Produktdetails:
            </label>
            <p className="text-gray-700 text-base">
              Mit unserem neuen Lüftungssystem erhalten Sie die Kontrolle über
              die Luftqualität in Ihrem Zuhause und verbessern gleichzeitig die
              Energieeffizienz. Unsere Systeme sind mit modernster Technologie
              ausgestattet und lassen sich leicht in jedes Zuhause integrieren.
            </p>
          </div>

          <a
            href="/realisierung"
            className="wp-btn block text-center bg-red-500 text-white font-semibold text-lg py-3 px-6 rounded-md hover:bg-red-600 transition duration-300"
          >
            Jetzt informieren!
          </a>
        </div>

        <div className="features text-red-500 font-semibold text-2xl mb-4">
          Schlüsselaspekte:
        </div>
        <ul className="list-disc list-inside text-gray-700 text-sm">
          <li>Kontinuierliche Frischluftzufuhr für ein gesundes Raumklima</li>
          <li>
            Kontrollierte Luftfeuchtigkeit zur Vermeidung von Schimmelbildung
          </li>
          <li>Hochwertige Filterung für saubere und partikelfreie Luft</li>
          <li>Energieeffizienz durch Wärmerückgewinnung</li>
          <li>Einfache Planung und Installation durch Experten</li>
        </ul>
        <div className="flex flex-col gap-2">
          <h2 className="features text-red-500 font-semibold text-2xl mb-4">
            Die Bedeutung einer guten Belüftung:
          </h2>
          <p className="text-gray-700 text-sm">
            Eine hohe Luftqualität ist wichtig für unser Wohlbefinden und unsere
            Gesundheit. Durch Frischluftzufuhr fühlen wir uns in unseren Räumen
            wohl und voller Energie. Die Luftfeuchtigkeit hilft auch, Schimmel
            zu verhindern.
          </p>
          <h3 className="features text-red-500 font-semibold text-md my-4">
            Frische Luft für Ihr Wohlbefinden
          </h3>
          <p className="text-gray-700 text-sm">
            Wenn die Luft nicht gut ist, fühlen wir uns müde, haben
            Kopfschmerzen und können uns nicht konzentrieren. Ein gutes
            Lüftungssystem sorgt dafür, dass immer frische Luft da ist. So
            bleiben wir erfrischt und leistungsfähig.
          </p>
          <h3 className="features text-red-500 font-semibold text-md my-4">
            Schimmelprävention durch kontrollierte Luftfeuchtigkeit
          </h3>
          <ul className="list-disc list-inside text-gray-700 text-sm">
            <li>
              Die Luftfeuchtigkeit sollte zwischen 40 und 60 Prozent sein, um
              Schimmel zu verhindern.
            </li>
            <li>
              Moderne Lüftungssysteme halten die Luftfeuchtigkeit automatisch
              auf einem guten Niveau.
            </li>
            <li>
              Regelmäßiges Lüften allein reicht oft nicht, um Schäden zu
              vermeiden.
            </li>
          </ul>
          <p className="text-gray-700 text-sm mt-5">
            Ein kluges Lüftungskonzept schafft ein gesundes Raumklima. Es
            verbessert Ihr Wohlbefinden. Investieren Sie in Ihr Zuhause - Ihre
            Gesundheit wird es Ihnen danken.
          </p>
          <p className="text-gray-700 text-md mt-5">
            "Gute Belüftung ist essenziell für Ihr Wohlbefinden und schützt vor
            Schimmel."
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="features text-red-500 font-semibold text-2xl mb-4">
            Funktionsweise eines Lüftungssystems
          </h2>
          <p className="text-gray-700 text-sm">
            Ein modernes Lüftungssystem sorgt für frische Luft in Ihrem Zuhause.
            Es saugt frische Luft an, filtert sie und leitet sie in die Räume.
            Gleichzeitig wird die verbrauchte Luft nach außen abgeführt. Dadurch
            entsteht ein ausgewogener Luftstrom. So wird ein optimales Raumklima
            geschaffen. Die Lüftungskanäle sind das Herz des Systems. Sie
            transportieren den Luftstrom in die einzelnen Räume. Moderne Systeme
            regulieren den Frischluftaustausch genau. Dies sorgt für ein
            gesundes Raumklima. Durch Frischluftzufuhr und Abtransport
            verbrauchter Luft vermeidet das System Schadstoffe und Feuchtigkeit.
            So verbessert es das Wohlbefinden und die Gesundheit der Bewohner.
          </p>

          {/* YouTube Video */}
          <div className="mt-4">
            <iframe
              width="screen"
              height="315"
              src="https://www.youtube.com/embed/xJGhD9Hwkp4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>

          {/* Component Table */}
          <div className="overflow-x-auto">
            <table className="table-auto mt-6 border-collapse w-full text-gray-700 text-sm px-1">
              <thead>
                <tr>
                  <th className="border px-4 py-2 text-left">Komponente</th>
                  <th className="border px-4 py-2 text-left">Funktion</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">Ansaugöffnung</td>
                  <td className="border px-4 py-2">
                    Nimmt die Frischluft von außen auf
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Luftfilter</td>
                  <td className="border px-4 py-2">
                    Reinigt die Luft von Partikeln und Schadstoffen
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Lüftungskanäle</td>
                  <td className="border px-4 py-2">
                    Transportieren die Luft in die einzelnen Räume
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Abluftöffnung</td>
                  <td className="border px-4 py-2">
                    Führt die verbrauchte Luft nach außen ab
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <p className="text-gray-700 text-md mt-5">
            "Ein effizientes Lüftungssystem ist der Schlüssel zu einem
            angenehmen und gesunden Raumklima."
          </p>
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="features text-red-500 font-semibold text-2xl mb-4">
            Arten von Lüftungssystemen
          </h2>
          <p className="text-gray-700 text-sm">
            Es gibt verschiedene Arten, ein Gebäude zu belüften. Zentrale
            Lüftungsanlagen und dezentrale Lüftungsgeräte sind zwei der
            bekanntesten Systeme. Sie unterscheiden sich in Struktur und
            Funktionsweise.
          </p>

          <h3 className="text-gray-800 font-semibold text-xl mt-4">
            Zentrale Lüftungsanlagen
          </h3>
          <p className="text-gray-700 text-sm">
            Zentrale Lüftungsanlagen nutzen ein zentrales Gerät, um das Gebäude
            zu versorgen. Sie saugen verbrauchte Luft ab, erwärmen oder kühlen
            sie, und leiten sie dann in die Räume. Dies ermöglicht eine zentrale
            Steuerung und Optimierung der Lüftung. Der Einbau einer solchen
            Anlage erfordert jedoch mehr Aufwand.
          </p>

          <h3 className="text-gray-800 font-semibold text-xl mt-4">
            Dezentrale Lüftungsgeräte
          </h3>
          <p className="text-gray-700 text-sm">
            Dezentrale Lüftungsgeräte werden in jedem Raum installiert. Sie
            saugen die Luft ab, filtern und konditionieren sie separat. Dies ist
            einfacher zu installieren, da keine große Infrastruktur nötig ist.
            Die Steuerung kann jedoch komplexer sein. Die Wahl hängt von der
            Größe und Nutzung des Gebäudes ab. Fachleute können helfen, das
            beste System für Ihre Bedürfnisse zu finden.
          </p>

          {/* Table for comparison */}
          <div className="overflow-x-auto">
            <table className="table-auto mt-6 border-collapse w-full text-gray-700 text-sm">
              <thead>
                <tr>
                  <th className="border px-4 py-2 text-left">Merkmal</th>
                  <th className="border px-4 py-2 text-left">
                    Zentrale Lüftungsanlagen
                  </th>
                  <th className="border px-4 py-2 text-left">
                    Dezentrale Lüftungsgeräte
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="border px-4 py-2">Aufbau</td>
                  <td className="border px-4 py-2">
                    Zentrales Gerät, das das gesamte Gebäude versorgt
                  </td>
                  <td className="border px-4 py-2">
                    Eigenständige Geräte in einzelnen Räumen
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Installation</td>
                  <td className="border px-4 py-2">
                    Aufwendiger Einbau der Infrastruktur
                  </td>
                  <td className="border px-4 py-2">
                    Einfachere Installation ohne umfassende Baumaßnahmen
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Steuerung</td>
                  <td className="border px-4 py-2">
                    Zentrale Steuerung und Optimierung
                  </td>
                  <td className="border px-4 py-2">
                    Komplexere Einzelsteuerung der Geräte
                  </td>
                </tr>
                <tr>
                  <td className="border px-4 py-2">Geeignet für</td>
                  <td className="border px-4 py-2">Größere Gebäude</td>
                  <td className="border px-4 py-2">
                    Kleinere bis mittlere Gebäude
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <h3 className="text-gray-800 font-semibold text-xl mt-6">
            Lüftungssystem mit Wärmerückgewinnung
          </h3>
          <p className="text-gray-700 text-sm">
            Moderne Lüftungssysteme verbessern die Energieeffizienz in Ihrem
            Zuhause. Eine wichtige Technologie dabei ist die Wärmerückgewinnung.
            Sie nutzt die Abwärme der Luft, um die neue Luft zu wärmen, bevor
            sie eingeht. Dieses System senkt Ihre Heizkosten deutlich. Je
            nachdem, welche Technologie Sie wählen, können Sie bis zu 90% der
            Wärme zurückgewinnen. So sparen Sie nicht nur Energie, sondern
            genießen auch ein angenehmes Raumklima.
          </p>

          <h4 className="text-gray-800 font-semibold text-md mt-4">
            Energieeffizienz durch Wärmerückgewinnung
          </h4>
          <p className="text-gray-700 text-sm">
            Im Zentrum eines solchen Systems steht der Wärmetauscher. Er
            ermöglicht den Wärmeaustausch zwischen den Luftströmen, ohne sie zu
            vermischen. So wird die verlorene Energie effektiv genutzt und dem
            Haus wieder gegeben.
          </p>

          {/* Bullet points */}
          <ul className="list-disc ml-5 text-gray-700 text-sm mt-2">
            <li>Bis zu 90% Wärmerückgewinnung möglich</li>
            <li>Deutliche Reduzierung der Heizkosten</li>
            <li>Optimale Energieeffizienz durch intelligente Technologie</li>
          </ul>

          <p className="text-gray-700 text-sm mt-4">
            Ein Lüftungssystem mit Wärmerückgewinnung ist eine kluge
            Investition. Es sorgt für ein energieeffizientes Raumklima und
            schützt die Umwelt.
          </p>
        </div>
        <div className="wp-img mb-6">
          <img
            src={bg2}
            alt="Lüftungssystem Beispiel"
            className="mx-auto max-w-full h-auto"
          />
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="features text-red-500 font-semibold text-2xl mb-4">
            Filterung und Luftqualität
          </h2>
          <p className="text-gray-700 text-sm">
            Ein gutes Lüftungssystem ist nicht nur effizient, sondern auch gut
            für Ihre Gesundheit. Partikel- und Pollenfilter entfernen
            Schadstoffe, Staub und Allergene. So atmen Sie saubere Luft in Ihren
            Räumen.
          </p>

          <h3 className="text-gray-800 font-semibold text-xl mt-4">
            Partikel- und Pollenfilter für saubere Luft
          </h3>
          <p className="text-gray-700 text-sm">
            Die Filterung der Zuluft ist wichtig für Luftqualität in Ihrem
            Zuhause. Partikelfilter fangen Feinstaub und Ruß ab. Pollenfilter
            entfernen Allergene. So atmen Sie unbeschwert und schützen Ihre
            Gesundheit.
          </p>

          <blockquote className="text-gray-700 italic text-md mt-4 border-l-4 border-gray-300 pl-4">
            "Eine gute Belüftung und saubere Luft sind entscheidend für mein
            Wohlbefinden - das Lüftungssystem in meinem Zuhause gibt mir da ein
            gutes Gefühl."
          </blockquote>

          {/* Bullet points */}
          <ul className="list-disc ml-5 text-gray-700 text-sm mt-4">
            <li>Umfassender Schutz vor Schadstoffen und Allergenen</li>
            <li>Einfache Wartung durch regelmäßigen Filterwechsel</li>
            <li>Verbesserte Luftqualität für ein gesünderes Raumklima</li>
          </ul>

          <p className="text-gray-700 text-sm mt-4">
            Mit einem modernen Lüftungssystem und Partikel- und Pollenfiltern
            kontrollieren Sie die Luftqualität. Kontaktieren Sie uns für
            Beratung unter{" "}
            <span className="font-semibold">+48 512 548 812</span>.
          </p>

          <h2 className="features text-red-500 font-semibold text-2xl mt-8 mb-4">
            Planung und Installation
          </h2>
          <p className="text-gray-700 text-sm">
            Eine gute Planung und eine professionelle Installation sind wichtig.
            Sie sorgen dafür, dass Ihr Lüftungssystem gut funktioniert. Experten
            wissen, wie man das am besten macht.
          </p>

          <p className="text-gray-700 text-sm">
            Die Spezialisten von{" "}
            <span className="font-semibold">+48 512 548 812</span> helfen Ihnen
            gerne. Sie beraten über Planungsmöglichkeiten für Ihr Zuhause. Sie
            schauen sich Ihre Immobilie und Ihre Wünsche genau an.
          </p>

          {/* Bullet points */}
          <ul className="list-disc ml-5 text-gray-700 text-sm mt-4">
            <li>Detaillierte Bedarfsanalyse für Ihre Immobilie</li>
            <li>Planung des optimalen Lüftungskonzepts</li>
            <li>Fachgerechte Installation durch erfahrene Techniker</li>
            <li>Komplette Systemintegration für nahtlosen Betrieb</li>
          </ul>

          <p className="text-gray-700 text-sm mt-4">
            Unsere Experten planen und installieren Ihr Lüftungssystem
            professionell. So ist es perfekt auf Sie abgestimmt. Sie genießen
            ein angenehmes Raumklima und profitieren von der kontrollierten
            Belüftung.
          </p>
        </div>

        <div className="wp-img mb-6">
          <img
            src={bg3}
            alt="Lüftungssystem Beispiel"
            className="mx-auto max-w-full h-auto"
          />
        </div>
        <div className="flex flex-col gap-2">
          <h2 className="features text-red-500 font-semibold text-2xl mb-4">
            Wartung und Reinigung
          </h2>
          <p className="text-gray-700 text-sm">
            Ein Lüftungssystem braucht regelmäßige Wartung und Reinigung. Das
            ist wichtig, damit es gut funktioniert. Besonders wichtig ist der
            Filter-Wechsel.
          </p>

          <h3 className="text-gray-800 font-semibold text-xl mt-4">
            Regelmäßiger Filter-Wechsel
          </h3>
          <p className="text-gray-700 text-sm">
            Die Filter reinigen die Luft. Sie müssen oft gewechselt werden. Ein
            Filterwechsel sollte alle 6 bis 12 Monate erfolgen.
          </p>

          {/* Bullet points */}
          <ul className="list-disc ml-5 text-gray-700 text-sm mt-4">
            <li>Überprüfen Sie die Filterleistung regelmäßig</li>
            <li>
              Tauschen Sie die Filter rechtzeitig aus, bevor sie verstopfen
            </li>
            <li>
              Nutzen Sie hochwertige Originalfilter für Ihr Lüftungssystem
            </li>
          </ul>

          <p className="text-gray-700 text-sm mt-4">
            Damit die Luftqualität gut bleibt, ist regelmäßige Wartung wichtig.
            Unser Kundenservice hilft Ihnen gerne. Rufen Sie uns an unter{" "}
            <span className="font-semibold">+48 512 548 812</span>.
          </p>

          <blockquote className="text-gray-700 italic text-md mt-4 border-l-4 border-gray-300 pl-4">
            "Regelmäßige Wartung ist der Schlüssel zur Langlebigkeit Ihres
            Lüftungssystems."
          </blockquote>

          <h2 className="features text-red-500 font-semibold text-2xl mt-8 mb-4">
            Vorteile eines effizienten Lüftungssystems
          </h2>
          <p className="text-gray-700 text-sm">
            Ein modernes Lüftungssystem verbessert Ihr Zuhause erheblich. Es
            schafft ein gesundes Raumklima mit der richtigen Luftfeuchtigkeit.
            So vermeidest du Schimmel und schaffst ein angenehmes Ambiente.
          </p>

          <p className="text-gray-700 text-sm mt-4">
            Ein solches System erhöht auch die Energieeffizienz Ihres Hauses.
            Durch Wärmerückgewinnung wird die Abluft genutzt, um die Frischluft
            zu wärmen. Das spart Heizkosten, ohne das Raumklima zu
            beeinträchtigen.
          </p>

          <p className="text-gray-700 text-sm mt-4">
            Es verbessert auch die Luftqualität in Ihrem Zuhause. Moderne Geräte
            filtern Partikel, Pollen und Schadstoffe effektiv. So atmet man
            stets saubere Luft. Ein Lüftungssystem von Zehnder macht Ihr Zuhause
            komfortabler. Für mehr Infos kontaktieren Sie uns unter{" "}
            <span className="font-semibold">+49 512 548 812</span>.
          </p>

          <h2 className="features text-red-500 font-semibold text-2xl mt-8 mb-4">
            FAQ
          </h2>

          {/* FAQ Section */}
          <div className="flex flex-col gap-2">
            <h3 className="text-gray-800 font-semibold text-lg mt-4">
              Was sind die Vorteile eines effizienten Lüftungssystems?
            </h3>
            <p className="text-gray-700 text-sm">
              Ein modernes Lüftungssystem bringt viele Vorteile. Es sorgt für
              frische Luft und die richtige Luftfeuchtigkeit. Es macht Ihr
              Zuhause gesünder und spart Energie.
            </p>

            <h3 className="text-gray-800 font-semibold text-lg mt-4">
              Wie funktioniert ein Lüftungssystem?
            </h3>
            <p className="text-gray-700 text-sm">
              Ein Lüftungssystem arbeitet mit Kanälen, die Luft zirkulieren
              lassen. Frische Luft wird gesaugt und gefiltert. Dann wird sie in
              die Räume gebracht. Verbrauchte Luft wird nach draußen abgeführt.
              So wechselt die Luft kontrolliert und das Raumklima bleibt
              optimal.
            </p>

            <h3 className="text-gray-800 font-semibold text-lg mt-4">
              Welche Arten von Lüftungssystemen gibt es?
            </h3>
            <p className="text-gray-700 text-sm">
              Es gibt viele Arten von Lüftungssystemen. Zentrale Anlagen
              versorgen das ganze Gebäude. Dezentrale Geräte sind für einzelne
              Räume. Die Wahl hängt von der Größe und den Bedürfnissen des
              Gebäudes ab.
            </p>

            <h3 className="text-gray-800 font-semibold text-lg mt-4">
              Wie kann ein Lüftungssystem Energie sparen?
            </h3>
            <p className="text-gray-700 text-sm">
              Moderne Systeme nutzen Abwärme, um die Luft zu wärmen. Das spart
              viel Energie. So fallen die Heizkosten. Manche Systeme können bis
              zu 90% der Wärme zurückgewinnen.
            </p>

            <h3 className="text-gray-800 font-semibold text-lg mt-4">
              Wie wird die Luftqualität durch ein Lüftungssystem verbessert?
            </h3>
            <p className="text-gray-700 text-sm">
              Ein gutes System hat Filter, die Schmutz entfernen. So bleibt die
              Luft rein. Das ist gut für Allergiker und Asthmatiker.
            </p>

            <h3 className="text-gray-800 font-semibold text-lg mt-4">
              Wie wird ein Lüftungssystem gewartet und gereinigt?
            </h3>
            <p className="text-gray-700 text-sm">
              Ein Lüftungssystem braucht regelmäßige Pflege. Wichtig ist der
              Wechsel der Filter. So bleibt das System effizient.
            </p>
          </div>
        </div>
      </article>
      <RecommendedBlogs />
    </div>
  );
};

export default BlogComponent;
