import React, { useState } from "react";
import { Reveal } from "../Animation/Reveal";
import ref1 from "../../assets/ref1.jpg";
import ref2 from "../../assets/ref2.jpg";
import ref3 from "../../assets/ref3.jpg";
import { AiOutlineClose } from "react-icons/ai";

const About1 = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImg, setCurrentImg] = useState("");

  const openModal = (imgSrc) => {
    setCurrentImg(imgSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="px-4 2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 mt-[100px]">
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row">
          <div className="flex flex-col justify-center w-full lg:w-5/12">
            <h1 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Über uns
            </h1>
            <p className="text-sm font-normal leading-6 text-gray-600 ">
              Unsere Firma ist führend in der Herstellung und Montage von
              Fassaden sowie in der Produktion von Fertigteilen. Wir bieten
              maßgeschneiderte Lösungen, die auf die spezifischen Bedürfnisse
              jedes Projekts zugeschnitten sind. Unsere Produkte zeichnen sich
              durch Qualität, Langlebigkeit und modernes Design aus. Wir setzen
              auf innovative Materialien und Technologien, um energetisch
              optimierte und ästhetisch ansprechende Gebäudehüllen zu schaffen.
              Durch unsere effizienten Produktionsprozesse garantieren wir eine
              schnelle Umsetzung und hohe Kosteneffizienz. Unser Team aus
              erfahrenen Ingenieuren und Technikern sorgt für eine
              professionelle Beratung und Ausführung aller Bauvorhaben.
            </p>
          </div>

          <div className="w-full lg:w-8/12 ">
            <img
              className="w-full h-full"
              src="https://i.ibb.co/FhgPJt8/Rectangle-116.png"
              alt="A group of People"
            />
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 pt-12 lg:flex-row">
          <div className="flex flex-col justify-center w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Unsere Geschichte
            </h2>
            <p className="text-sm font-normal leading-6 text-gray-600 ">
              Die Geschichte unseres Unternehmens beginnt im Jahr 2016 unter dem
              Namen „MARGO“. Im Zuge eines allgemeinen Rebrandings änderte das
              Unternehmen seinen Namen in „LECHBUD“, unter dem es bis heute
              firmiert.
            </p>
          </div>
          <div className="w-full lg:w-8/12 lg:pt-8">
            <h1 className="text-sm font-normal leading-6 text-center text-gray-600">
              {" "}
              Unsere Referenzen
            </h1>
            <div className="grid grid-cols-1 rounded-md shadow-lg md:grid-cols-3 sm:grid-cols-2 lg:gap-4">
              <div
                className="flex flex-col items-center justify-center p-4 pb-6 cursor-pointer"
                onClick={() => openModal(ref1)}
              >
                <img
                  className=" md:block"
                  src={ref1}
                  alt="Alexa featured Img"
                />
              </div>

              <div
                className="flex flex-col items-center justify-center p-4 pb-6 cursor-pointer"
                onClick={() => openModal(ref2)}
              >
                <img
                  className=" md:block"
                  src={ref2}
                  alt="Alexa featured Img"
                />
              </div>
              <div
                className="flex flex-col items-center justify-center p-4 pb-6 cursor-pointer"
                onClick={() => openModal(ref3)}
              >
                <img
                  className=" md:block"
                  src={ref3}
                  alt="Alexa featured Img"
                />
              </div>
            </div>
          </div>
        </div>
      </Reveal>
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="p-3 bg-white rounded">
            <img
              src={currentImg}
              alt="Modal Content"
              className="max-h-[80vh] max-w-[80vw]"
            />
            <button
              onClick={closeModal}
              className="absolute text-white top-4 right-4"
            >
              <AiOutlineClose size={25} />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default About1;
