// blogsData.js
export const blogs = [
  {
    id: 1,
    title: "Warum regelmäßige Rohrinspektionen wichtig sind",
    image: require("../../assets/blogs/blog1.png"), // Zmień ścieżkę do obrazu na odpowiednią
    description:
      "Erfahren Sie, warum regelmäßige Rohrinspektionen Ihr Zuhause vor kostspieligen Schäden bewahren können...",
    content: `In vielen Haushalten werden die Rohrleitungen oft übersehen, bis ein größeres Problem auftritt. 
              Regelmäßige Rohrinspektionen sind jedoch entscheidend, um teure Reparaturen zu vermeiden und die Lebensdauer Ihres Rohrsystems zu verlängern.

              Bei einer Inspektion können Fachleute potenzielle Lecks, Korrosion oder Blockaden frühzeitig erkennen, bevor sie zu ernsteren Problemen führen. 
              Besonders in älteren Gebäuden, in denen die Rohre Jahrzehnte alt sind, ist dies von großer Bedeutung. 

              Ein weiterer Vorteil regelmäßiger Inspektionen ist die Prävention von Wasserschäden, die oft hohe Kosten verursachen. 
              Feuchtigkeit, die aus undichten Rohren austritt, kann Schimmel und Bauschäden zur Folge haben, was eine teure Sanierung erforderlich macht.

              Abgesehen von der Vermeidung von Rohrbrüchen kann eine regelmäßige Inspektion auch dazu beitragen, die Wasserqualität in Ihrem Zuhause zu verbessern. 
              Verstopfungen oder schmutzige Rohre können die Hygiene beeinträchtigen, was sich langfristig negativ auf die Gesundheit Ihrer Familie auswirken kann.

              Zusammenfassend lässt sich sagen, dass regelmäßige Rohrinspektionen nicht nur Kosten sparen, sondern auch die Sicherheit und den Komfort in Ihrem Zuhause erhöhen.`,
  },
  {
    id: 2,
    title: "Tipps zur Vermeidung von verstopften Abflüssen",
    image: require("../../assets/blogs/blog2.png"), // Zmień ścieżkę do obrazu na odpowiednią
    description:
      "Verstopfte Abflüsse können lästig sein, aber mit diesen einfachen Tipps können Sie sie vermeiden...",
    content: `Verstopfte Abflüsse gehören zu den häufigsten Problemen im Haushalt. 
              Sie können unangenehme Gerüche und sogar Überschwemmungen verursachen, wenn sie nicht rechtzeitig behandelt werden. 
              Hier sind einige Tipps, um solche Probleme zu vermeiden:

              1. **Keine Essensreste im Abfluss**: Vermeiden Sie es, Speisereste oder Fett in den Abfluss zu spülen. 
              Diese können sich im Rohr festsetzen und zu Verstopfungen führen. Besonders problematisch sind Fette, die in kaltem Wasser aushärten und das Rohr blockieren können.

              2. **Abflusssiebe verwenden**: Ein einfaches Abflusssieb kann verhindern, dass Haare, Seifenreste und andere Partikel in den Abfluss gelangen. 
              Besonders in Badezimmern ist dies ein wichtiger Schritt, um Verstopfungen durch Haare zu verhindern.

              3. **Regelmäßige Reinigung**: Spülen Sie Ihre Abflüsse einmal pro Woche mit heißem Wasser, um Ablagerungen zu vermeiden. 
              Sie können auch eine Mischung aus Essig und Natron verwenden, um hartnäckige Ablagerungen zu entfernen. Gießen Sie zuerst Natron in den Abfluss, dann Essig, und spülen Sie nach einigen Minuten mit heißem Wasser nach.

              4. **Achtung bei Chemikalien**: Verwenden Sie chemische Abflussreiniger nur im Notfall, da sie die Rohre beschädigen können. 
              Natürliche Reinigungsmethoden sind sicherer und umweltfreundlicher.

              Durch diese einfachen Maßnahmen können Sie Verstopfungen effektiv vorbeugen und die Lebensdauer Ihrer Rohrleitungen verlängern.`,
  },
  {
    id: 3,
    title: "So erkennen Sie ein undichtes Rohr in Ihrem Zuhause",
    image: require("../../assets/blogs/blog3.png"), // Zmień ścieżkę do obrazu na odpowiednią
    description:
      "Ein undichtes Rohr kann erhebliche Schäden an Ihrem Haus verursachen. Erfahren Sie, wie Sie Lecks frühzeitig erkennen können...",
    content: `Ein undichtes Rohr kann schnell zu einem teuren Problem werden, wenn es nicht rechtzeitig bemerkt wird. 
              Doch wie erkennt man ein Leck, bevor es großen Schaden anrichtet? Hier sind einige Anzeichen, auf die Sie achten sollten:

              1. **Wasserflecken an Wänden oder Decken**: Feuchte Stellen oder Verfärbungen an Wänden und Decken können auf ein Leck hinweisen. 
              Besonders in Badezimmern oder Küchen können undichte Rohre im Mauerwerk Wasser austreten lassen, was zu schwerwiegenden Schäden führen kann.

              2. **Plötzlicher Druckabfall**: Wenn der Wasserdruck plötzlich abnimmt, könnte ein Rohrbruch die Ursache sein. 
              Dies kann insbesondere bei alten Rohrleitungen ein Problem darstellen, bei denen Korrosion die Rohre schwächt.

              3. **Erhöhte Wasserrechnung**: Ein unerklärlich hoher Wasserverbrauch kann ein Zeichen dafür sein, dass irgendwo ein Leck vorhanden ist. 
              Achten Sie auf Ihre Wasserrechnung und vergleichen Sie sie mit den vorherigen Monaten. 

              4. **Schimmelbildung**: Schimmel wächst oft dort, wo Wasser austritt. 
              Wenn Sie plötzlich Schimmel an ungewöhnlichen Stellen bemerken, insbesondere in Bereichen, die normalerweise trocken sein sollten, überprüfen Sie Ihre Rohre.

              5. **Geräusche in den Rohren**: Wenn Sie ein Zischen oder Tropfen in den Rohren hören, obwohl alle Wasserhähne geschlossen sind, kann dies auf ein Leck hindeuten.

              Wenn Sie eines dieser Anzeichen bemerken, sollten Sie sofort einen Fachmann hinzuziehen, um das Leck zu beheben, bevor größere Schäden entstehen. 
              Denken Sie daran: Je früher ein Leck entdeckt wird, desto geringer sind die Kosten für die Reparatur.`,
  },
];
