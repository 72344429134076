import Mainimg from "../components/Main/Mainimg";
import Experience from "../components/Main/Experience";
import AboutUsSmall from "../components/Main/AboutUsSmall";
import OfferteSmall from "../components/Main/OfferteSmall";
import { Helmet } from "react-helmet-async";
const Home = () => {
  return (
    <div className="relative">
      <Helmet>
        <title>
          Willkommen bei Lechbud - Qualitätsprodukte und Dienstleistungen
        </title>
        <meta
          name="description"
          content="Lechbud bietet Ihnen die besten Produkte und Dienstleistungen. Entdecken Sie unser Angebot!"
        />
        <meta
          name="keywords"
          content="Produkte, Dienstleistungen, Qualität, Angebot"
        />
        <meta
          property="og:title"
          content="Willkommen bei Lechbud - Qualitätsprodukte und Dienstleistungen"
        />
        <meta
          property="og:description"
          content="Lechbud bietet Ihnen die besten Produkte und Dienstleistungen."
        />
        <meta property="og:url" content="https://lechbud.de/" />
        <meta property="og:type" content="website" />
      </Helmet>
      <Mainimg />
      <Experience />
      <AboutUsSmall />
      <OfferteSmall />
    </div>
  );
};

export default Home;
