import bg from "../../assets/dom.png";
import { Reveal } from "../Animation/Reveal";
const Hero = () => {
  return (
    <div className="relative inset-0 flex items-center justify-center w-full h-[80vh]">
      <img
        src={bg}
        alt=""
        className="fixed w-full h-screen z-[-1] object-cover"
      />
      <div className="fixed inset-0 w-full h-full bg-black opacity-30"></div>
      <Reveal>
        <div className="relative flex flex-col items-center gap-5 text-center z-2 md:mt-0 mt-[100px]">
          <h1 className="pb-4 text-4xl font-bold leading-9 text-white sm:text-6xl ">
            Wir machen speziell für
            <br></br>Ihr Projekt{" "}
          </h1>
          <h2 className="text-sm font-normal leading-6 text-white max-w-[700px] px-5">
            Die Fassade ist ein untrennbarer Bestandteil eines jeden Gebäudes
            und eine Art Schaufenster seines Charakters und Stils. Sein Aussehen
            und die verwendeten Materialien sind die ersten Elemente, die die
            Aufmerksamkeit der Passanten auf sich ziehen und viele wichtige
            Informationen darüber vermitteln, mit wem und was sie es zu tun
            haben.
          </h2>
        </div>
      </Reveal>
    </div>
  );
};
export default Hero;
