import About1 from "../components/About/About1";
import { Helmet } from "react-helmet-async";
const About = () => {
  return (
    <div>
      <Helmet>
        <title>Über uns - Lechbud</title>
        <meta
          name="description"
          content="Erfahren Sie mehr über Lechbud, unsere Mission, unser Team und wie wir unsere Kunden unterstützen."
        />
        <meta
          name="keywords"
          content="Über uns, Lechbud, Team, Mission, Unterstützung"
        />
        <meta property="og:title" content="Über uns - Lechbud" />
        <meta
          property="og:description"
          content="Erfahren Sie mehr über unsere Mission und unser engagiertes Team."
        />
        <meta property="og:url" content="http://localhost:3000/uberuns" />
        <meta property="og:type" content="website" />
      </Helmet>
      <About1 />
    </div>
  );
};
export default About;
