import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import icon1 from "../../assets/icon1.png";
import icon2 from "../../assets/icon2.png";
import icon3 from "../../assets/icon3.png";
import Wave from "react-wavify";
import { Reveal } from "../Animation/Reveal";

const OfferteSmall = () => {
  return (
    <div className="flex flex-col gap-8 text-center  py-[150px] relative  bg-white h-full">
      <Wave
        className="absolute top-[-50px] z-[1]"
        fill="white"
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.2,
          points: 10,
        }}
      />
      <Wave
        className="absolute bottom-[-50px] z-[1]"
        fill="white"
        paused={false}
        options={{
          height: 20,
          amplitude: 20,
          speed: 0.2,
          points: 10,
        }}
        style={{ transform: "rotate(180deg)" }}
      />
      <Reveal>
        <h1 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl z-[2]">
          {" "}
          Unser Angebot:
        </h1>
      </Reveal>
      <Swiper
        pagination={{ dynamicBullets: true }}
        modules={[Pagination]}
        className="w-full h-[80vh] md:h-[60vh] bg-black"
        breakpoints={{
          // when window width is >= 768px (for tablets and larger screens)
          768: {
            slidesPerView: 3,
          },
          // when window width is < 768px (for mobile devices)
          0: {
            slidesPerView: 1,
          },
        }}
      >
        <SwiperSlide className="text-center text-[18px] bg-[#fff] flex flex-col justify-center items-center">
          {" "}
          <Reveal>
            <div className="flex flex-col items-center gap-[40px] w-full h-full p-2 m-auto">
              <img src={icon1} alt="" className="w-[200px] h-[200px]"></img>
              <h2 className="text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
                Fassaden
              </h2>
              <p className="text-sm max-w-[80%] pb-5">
                Diese Fassade zeichnet sich durch ihre klare, moderne
                Architektur aus. Große Fensterflächen lassen viel Licht ins
                Innere und bieten eine offene Atmosphäre. Die Verwendung von
                Naturmaterialien wie Holz und Stein betont die Nachhaltigkeit
                des Gebäudes.
              </p>
            </div>{" "}
          </Reveal>
        </SwiperSlide>

        <SwiperSlide className="text-center text-[18px] bg-[#fff] flex flex-col justify-center items-center">
          <Reveal>
            <div className="flex flex-col items-center gap-[40px] w-full h-full p-2 m-auto">
              <img src={icon2} alt="" className="w-[200px] h-[200px]" />
              <h2 className="text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
                Schalung
              </h2>
              <p className="text-sm max-w-[80%] pb-5">
                Schalungen sind essenzielle Bauelemente im Betonbau, die dazu
                dienen, den Beton in Form zu halten, bis er aushärtet. Sie sind
                wiederverwendbar und gewährleisten die Maßgenauigkeit sowie die
                strukturelle Integrität der Bauwerke. Unsere Schalungssysteme
                bieten effiziente Lösungen für komplexe und einfache
                Bauvorhaben.
              </p>
            </div>{" "}
          </Reveal>
        </SwiperSlide>

        <SwiperSlide className="text-center text-[18px] bg-[#fff] flex flex-col justify-center items-center">
          <Reveal>
            <div className="flex flex-col items-center gap-[40px] w-full h-full p-2 m-auto">
              <img src={icon3} alt="" className="w-[200px] h-[200px]" />
              <h2 className="text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
                Fertigteilen
              </h2>
              <p className="text-sm max-w-[80%] pb-5">
                Die Fertigteile aus Beton sind für ihre hohe Qualität und
                Langlebigkeit bekannt. Ihre standardisierten Maße erleichtern
                schnelle und effiziente Bauarbeiten. Diese Bauelemente sind
                ideal für moderne, kosteneffiziente Projekte.
              </p>
            </div>
          </Reveal>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default OfferteSmall;
