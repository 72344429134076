// src/components/BlogPage.jsx
import React from "react";
import { useParams } from "react-router-dom"; // Assuming you're using React Router
import { blogs } from "./blogsData";
import RecommendedBlogs from "./RecommendedBlogs";

const BlogPage = () => {
  const { id } = useParams();
  const blog = blogs.find((b) => b.id === parseInt(id));

  if (!blog) {
    return <div>Blog not found</div>;
  }

  return (
    <div className=" mt-[150px]">
      <article className="bg-white border border-gray-200 p-6 rounded-md max-w-6xl mx-auto mt-[150px] shadow-lg flex flex-col gap-4 font-sans">
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full h-60 object-cover rounded-md"
        />
        <h1 className="text-3xl font-bold mt-4">{blog.title}</h1>
        <p className="mt-4">{blog.content}</p>
      </article>
      <div className="mt-10 border-t-2 border-black">
        <RecommendedBlogs />
      </div>
    </div>
  );
};

export default BlogPage;
