// src/components/RecommendedBlogs.jsx
import React from "react";
import { blogs } from "./blogsData"; // Adjust the import path as necessary

const RecommendedBlogs = () => {
  // Shuffle the blogs array and take the first 2
  const shuffledBlogs = [...blogs].sort(() => 0.5 - Math.random()).slice(0, 2);

  return (
    <div className="mt-10 p-6 bg-white shadow-lg rounded-lg w-screen">
      <h2 className="text-2xl font-semibold mb-4">Empfohlene Blogs</h2>
      <div className="flex flex-wrap justify-center gap-5">
        {shuffledBlogs.map((blog) => (
          <div key={blog.id} className="basis-1/2 md:basis-1/3 lg:basis-1/4">
            <div className="border-b pb-4">
              <h3 className="text-xl font-medium text-gray-800">
                {blog.title}
              </h3>
              <img
                src={blog.image}
                alt={blog.title}
                className="mt-2 mb-2 rounded-md"
              />
              <p className="text-gray-600">{blog.description}</p>
              <a
                href={`/blog/${blog.id}`}
                className="inline-block mt-2 text-blue-600 hover:underline"
              >
                Weiterlesen
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendedBlogs;
