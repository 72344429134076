import React from "react";
import { blogs } from "./blogsData"; // import blog data
import bg from "../../assets/blogs/imgblog1.png";
const AllBlogs = () => {
  return (
    <div className="flex flex-col items-center mt-[150px]">
      <h1 className="text-4xl">Blogs</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 p-4 mt-[40px] px-4">
        {blogs.map((blog) => (
          <div key={blog.id} className="border rounded-lg p-4 shadow-lg">
            <img
              src={blog.image}
              alt={blog.title}
              className="w-full h-40 object-cover rounded-md"
            />
            <h2 className="text-xl font-bold mt-2">{blog.title}</h2>
            <p>{blog.description.split(".")[0]}...</p>{" "}
            {/* Show only the first sentence */}
            <a
              href={`/blog/${blog.id}`}
              className="text-blue-500 mt-2 inline-block"
            >
              Jetzt informieren!
            </a>
          </div>
        ))}
        <div key={4} className="border rounded-lg p-4 shadow-lg">
          <img src={bg} className="w-full h-40 object-cover rounded-md" />
          <h2 className="text-xl font-bold mt-2">
            {" "}
            Effizientes Lüftungssystem für Ihr Zuhause
          </h2>
          <p>
            Wussten Sie, dass eine durchschnittliche Familie pro Jahr bis zu
            40.000...
          </p>{" "}
          {/* Show only the first sentence */}
          <a href={`/blog/4`} className="text-blue-500 mt-2 inline-block">
            Jetzt informieren!
          </a>
        </div>
      </div>
    </div>
  );
};

export default AllBlogs;
