import { Link, useLocation } from "react-router-dom";

import bg1 from "../../assets/oferta/img3.jpg";
import bg2 from "../../assets/oferta/img2.jpg";
import bg3 from "../../assets/oferta/img4.jpg";
import bg4 from "../../assets/oferta/IMG-20241014-WA0020.jpg";
import bg5 from "../../assets/haizung.png";
import bg6 from "../../assets/klimatyzacja.png";
import bg7 from "../../assets/wentylacja.png";
import { Reveal } from "../Animation/Reveal";

const Main = () => {
  return (
    <div className="px-4 2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 mt-[100px] flex flex-col  gap-[200px]">
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row">
          <div className="flex flex-col justify-center w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl ">
              Fassade
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              Unsere Fassadenlösungen bieten modernen Schutz und Ästhetik für
              Ihr Gebäude. Mit hochwertigen Materialien sorgen wir für eine
              langlebige und ansprechende Außenhülle, die Ihr Objekt optimal in
              Szene setzt. Ob klassisch oder modern – wir gestalten Ihre Fassade
              individuell nach Ihren Wünschen und Bedürfnissen. Vertrauen Sie
              auf unsere Expertise, um sowohl Funktionalität als auch Design
              harmonisch zu vereinen.
            </p>
            <Link
              to="/angebot"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[150px] text-center mt-[25px]"
            >
              Angebot
            </Link>
          </div>

          <div className="w-full lg:w-8/12 ">
            <img
              className="w-full h-full rounded-3xl"
              src={bg1}
              alt="A group of People"
            />
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row ">
          {" "}
          <div className="flex items-center justify-center order-last w-full lg:w-8/12 lg:order-first">
            <img
              className="h-full md:h-[90vh] object-contain rounded-3xl"
              src={bg2}
              alt="A group of People"
            />
          </div>
          <div className="flex flex-col justify-center order-1 w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Fertigteile
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              Unsere maßgeschneiderten Fertigteile garantieren eine schnelle und
              präzise Umsetzung Ihrer Bauprojekte. Sie bieten Flexibilität,
              Zeitersparnis und höchste Qualität – ideal für individuelle
              Bauanforderungen. Jedes Fertigteil wird nach strengen
              Qualitätsstandards gefertigt, um Ihnen eine sichere und
              zuverlässige Lösung zu bieten. Dank unserer umfangreichen Auswahl
              finden wir die perfekte Lösung für Ihre spezifischen Bauprojekte.
            </p>
            <Link
              to="/realisierung"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[180px] text-center mt-[25px]"
            >
              Realisierung
            </Link>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row">
          <div className="flex flex-col justify-center w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Schelung
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              Schalung Mit unseren Schalungssystemen erhalten Sie die perfekte
              Grundlage für Ihren Bau. Stabil, effizient und leicht anzupassen,
              sorgen sie für präzise und langlebige Betonarbeiten. Unsere
              Schalungslösungen sind darauf ausgelegt, die Bauzeit zu minimieren
              und die Sicherheit auf der Baustelle zu maximieren. Profitieren
              Sie von unserer Erfahrung und unserem Know-how, um Ihre
              Bauprojekte effizient und erfolgreich umzusetzen.
            </p>
            <Link
              to="/kontakt"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[150px] text-center mt-[25px]"
            >
              Kontakt
            </Link>
          </div>

          <div className="w-full lg:w-8/12 ">
            <img
              className="w-full h-full rounded-3xl"
              src={bg3}
              alt="A group of People"
            />
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row ">
          {" "}
          <div className="flex items-center justify-center order-last w-full lg:w-8/12 lg:order-first">
            <img
              className="h-full md:h-[90vh] object-contain rounded-3xl"
              src={bg4}
              alt="A group of People"
            />
          </div>
          <div className="flex flex-col justify-center order-1 w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Sanitär
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              Die Kategorie Sanitär bei Lechbud umfasst umfassende
              Dienstleistungen im Bereich Wasser- und Abwasserinstallationen
              sowie Zentralheizungssysteme. Unser erfahrenes Team von
              Installateuren sorgt für eine fachgerechte Ausführung der
              Arbeiten, von der Montage von Rohren und Verbindungsstücken über
              die Installation von Ventilen und Filtern bis hin zu
              fortschrittlichen Pumpsystemen. Wir verwenden ausschließlich
              Materialien von höchster Qualität, was Zuverlässigkeit und
              Langlebigkeit der Installationen garantiert. Lechbud ist Ihr
              vertrauenswürdiger Partner für die Umsetzung aller hydraulischen
              Projekte – von kleineren Reparaturen bis hin zu komplexen
              Installationssystemen.
            </p>
            <Link
              to="/realisierung"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[180px] text-center mt-[25px]"
            >
              Realisierung
            </Link>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row">
          <div className="flex flex-col justify-center w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Heizung
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              Die Kategorie Heizung bei Lechbud bietet umfassende Lösungen für
              effiziente und moderne Heizsysteme. Von der Installation
              traditioneller Heizkessel bis hin zu fortschrittlichen Wärmepumpen
              und Fußbodenheizungen – wir sorgen dafür, dass Ihre Räume stets
              angenehm beheizt sind. Unsere Systeme zeichnen sich durch hohe
              Energieeffizienz und Zuverlässigkeit aus, wodurch sowohl Komfort
              als auch Kosteneinsparungen gewährleistet werden.
            </p>
            <Link
              to="/kontakt"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[150px] text-center mt-[25px]"
            >
              Kontakt
            </Link>
          </div>

          <div className="w-full lg:w-8/12 flex items-center justify-center">
            <img
              className="w-full h-full md:w-[400px] md:h-[700px] rounded-3xl"
              src={bg5}
              alt="A group of People"
            />
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row ">
          {" "}
          <div className="flex items-center justify-center order-last w-full lg:w-8/12 lg:order-first">
            <img
              className="h-full md:h-[90vh] object-contain rounded-3xl"
              src={bg6}
              alt="A group of People"
            />
          </div>
          <div className="flex flex-col justify-center order-1 w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Lüftung
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              Die Kategorie Lüftung bei Lechbud umfasst innovative
              Lüftungssysteme, die für eine frische und saubere Raumluft sorgen.
              Unsere Experten planen und installieren sowohl zentrale als auch
              dezentrale Lüftungsanlagen, um optimale Luftqualität in Ihrem
              Gebäude sicherzustellen. Mit modernsten Filtern und
              energieeffizienten Lösungen tragen wir zur Verbesserung des
              Raumklimas bei und minimieren gleichzeitig den Energieverbrauch.
            </p>
            <Link
              to="/realisierung"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[180px] text-center mt-[25px]"
            >
              Realisierung
            </Link>
          </div>
        </div>
      </Reveal>
      <Reveal>
        <div className="flex flex-col justify-between gap-8 lg:flex-row">
          <div className="flex flex-col justify-center w-full lg:w-5/12">
            <h2 className="pb-4 text-3xl font-bold leading-9 text-gray-800 lg:text-4xl">
              Klima
            </h2>
            <p className="text-base font-normal leading-6 text-gray-600 ">
              In der Kategorie Klima bietet Lechbud maßgeschneiderte
              Klimatisierungslösungen für jeden Bedarf. Unsere Klimaanlagen
              sorgen für angenehme Temperaturen und Luftfeuchtigkeit, unabhängig
              von den Außenbedingungen. Wir bieten Installationen von
              Split-Klimaanlagen, Multisplit-Systemen und zentralen Klimaanlagen
              für private und gewerbliche Räume. Mit einem Fokus auf
              Energieeffizienz und Umweltfreundlichkeit stellen wir sicher, dass
              Ihr Raum immer perfekt klimatisiert ist.
            </p>
            <Link
              to="/kontakt"
              className="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent w-[150px] text-center mt-[25px]"
            >
              Kontakt
            </Link>
          </div>

          <div className="w-full lg:w-8/12 flex items-center justify-center">
            <img
              className="w-full h-full md:w-[400px] md:h-[700px] rounded-3xl"
              src={bg7}
              alt="A group of People"
            />
          </div>
        </div>
      </Reveal>
    </div>
  );
};
export default Main;
