import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { AiOutlineClose } from "react-icons/ai";
import { Reveal } from "../Animation/Reveal";

const Galeria = ({ photos }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [initialSlide, setInitialSlide] = useState(0);

  const handleImageClick = (index) => {
    setInitialSlide(index);
    setIsOpen(true);
  };

  return (
    <div className="flex flex-col items-center bg-white py-[150px] gap-[20px] mt-[-30px]">
      <Reveal>
        <h1 className="pb-4 text-4xl font-bold leading-9 text-center text-gray-800 sm:text-6xl">
          Unsere Projekte:
        </h1>
      </Reveal>
      <div className="grid w-full grid-cols-3 gap-4 px-4 md:grid-cols-5 lg:grid-cols-6">
        {photos.map((photo, index) => (
          <Reveal>
            <img
              key={index}
              src={photo.url}
              alt="Open Gallery"
              className="w-full cursor-pointer"
              onClick={() => handleImageClick(index)}
            />
          </Reveal>
        ))}
      </div>

      {/* Swiper Modal */}
      {isOpen && (
        <div className="fixed inset-0 top-0 left-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-75">
          <Swiper
            initialSlide={initialSlide}
            pagination={{ dynamicBullets: true }}
            slidesPerView={1}
            modules={[Pagination]}
            className="w-full h-[75vh] md:h-[60vh] bg-black"
          >
            {photos.map((photo, index) => (
              <SwiperSlide
                key={index}
                className="flex items-center justify-center w-full h-full bg-white "
              >
                <img
                  src={photo.url}
                  alt="Swiper image"
                  className="max-w-full max-h-full m-auto"
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <button
            onClick={() => setIsOpen(false)}
            className="absolute text-white top-4 right-4"
          >
            <AiOutlineClose size={25} />
          </button>
        </div>
      )}
    </div>
  );
};

export default Galeria;
