import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { Helmet } from "react-helmet-async";
const Contact = () => {
  const form = useRef();
  const [isSuccess, setIsSuccess] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_0430nyt", "template_xwzc7cq", form.current, {
        publicKey: "KEqBSAm2ZsyZrO85L",
      })
      .then(
        () => {
          console.log("SUCCESS!");
          setIsSuccess(true);
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div class="bg-white mt-[100px] z-[3]">
      <Helmet>
        <title>Kontakt - Lechbud</title>
        <meta
          name="description"
          content="Kontaktieren Sie uns bei Lechbud, um mehr über unsere Dienstleistungen und Produkte zu erfahren."
        />
        <meta name="keywords" content="Kontakt, E-Mail, Telefon, Lechbud" />
        <meta property="og:title" content="Kontakt - Lechbud" />
        <meta
          property="og:description"
          content="Nehmen Sie Kontakt mit uns auf, wir freuen uns auf Ihre Nachricht!"
        />
        <meta property="og:url" content="https://lechbud.de/kontakt" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div class="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 ">
          Kontaktieren Sie uns
        </h2>
        <p class="mb-8 lg:mb-16 font-light text-center text-gray-500  sm:text-xl">
          Schreiben Sie uns und wir melden uns so schnell wie möglich bei Ihnen
        </p>
        <form ref={form} onSubmit={sendEmail} class="space-y-8">
          <div>
            <label
              for="email"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Ihre E-Mail
            </label>
            <input
              name="email"
              type="email"
              id="email"
              class="shadow-sm bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-500 focus:border-primary-500 block w-full p-2.5 "
              placeholder="name@flowbite.com"
              required
            />
          </div>
          <div>
            <label
              for="subject"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Betreff
            </label>
            <input
              name="subject"
              type="text"
              id="subject"
              class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Lassen Sie uns wissen, wie wir Ihnen helfen können"
              required
            />
          </div>
          <div>
            <label
              for="phone"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Telephone
            </label>
            <input
              name="phone"
              type="tel"
              id="phone"
              class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500 "
              placeholder="+ 00 123 456 789"
              required
            />
          </div>
          <div class="sm:col-span-2">
            <label
              for="message"
              class="block mb-2 text-sm font-medium text-gray-900 "
            >
              Ihre Nachricht
            </label>
            <textarea
              name="message"
              id="message"
              rows="6"
              class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500 "
              placeholder="Hinterlasse einen Kommentar..."
            ></textarea>
          </div>
          <button
            type="submit"
            value="Send"
            class="bg-transparent text-[#FFA500] font-semibold py-2 px-4 border border-[#FFA500] rounded transition duration-300 hover:bg-[#FFA500] hover:text-white hover:border-transparent"
          >
            Nachricht senden
          </button>
        </form>
      </div>
    </div>
  );
};
export default Contact;
